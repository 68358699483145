div.subscriber-list { 
    margin-left: 8px; 
}

div.subscriber-list-record {
    padding: 5px 20px 5px 0px;
    background-color: #39a3f4;
    margin-right: 15px;
    margin-top: 8px;
}

div.giving-mfer {
    flex-grow: 0 !important;
}

div.greedy-mfer {
    flex-grow: 1 !important;
}
